import {
  ArrowPathIcon,
  ChartPieIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { api } from "~/utils/api";

export interface SettingsItem {
  name: string;
  icon: typeof ChartPieIcon | typeof ArrowPathIcon | typeof TrashIcon;
  id: string;
}
const useSettings = (
  initialSettingsItems: SettingsItem[],
  githubName: string,
  teamAccountId: string
) => {
  const { data: campaigns, refetch: refetchSettingsList } =
    api.campaign.campaignsWithoutGithubTargetId.useQuery({
      githubName,
      teamAccountId,
    });
  const settingItems = campaigns
    ? campaigns
        ?.map((campaign) => {
          return {
            name: `Add to ${campaign.name}`,
            icon: ChartPieIcon,
            id: campaign.id,
          };
        })
        .concat(initialSettingsItems)
    : initialSettingsItems;
  return { settingItems, refetchSettingsList };
};

export default useSettings;
