import { ChevronDownIcon } from "@primer/octicons-react";
import { useState } from "react";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import Button from "~/components/base/Button";
import { useLogger } from "~/providers/LoggerProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/utils/api";
import AddUserRepoButton from "./AddUserRepoButton";
import { trpc } from "~/store/trpc";
import { useCampaign } from "~/providers/CampaignProvider";

export default function AddUserRepoToCampaign({
  repoName,
}: Readonly<{
  repoName: string;
}>) {
  const { activeTeamAccount } = useTeamAccount();
  const logger = useLogger();
  const [teamAccountUser] = useTeamAccountUser();
  const [upsertingTarget, setUpsertingTarget] = useState<boolean>(false);

  const [repoTargetAdded, setRepoTargetAdded] = useState<boolean>(false);
  const { refetch, data: targetExists } =
    api.githubTarget.getTargetByDisplayNames.useQuery(repoName, {
      refetchInterval: (data) => {
        if (repoTargetAdded && data) {
          return 1000 * 60;
        }
        return false;
      },
    });
  const campaign = useCampaign();

  const [showDropdown, setShowDropdown] = useState(false);

  const handleFirstClick = async () => {
    setShowDropdown(true);

    try {
      setUpsertingTarget(true);

      await trpc.targetBatchCreation.create.mutate({
        type: "repo",
        campaignId: campaign.id ?? "",
        teamAccountId: activeTeamAccount?.id ?? "",
        userId: teamAccountUser?.id ?? "",
        githubUrls: [
          {
            url: repoName.toLowerCase(),
            customFields: [],
          },
        ],
      });
      setRepoTargetAdded(true);
      setTimeout(async () => {
        await refetch();
      }, 3000);
    } catch (err) {
      logger.error(err, "AddUserRepoToCampaign.tsx");
    }
    setUpsertingTarget(false);
  };

  return (
    <>
      {targetExists ? (
        <AddUserRepoButton
          nameWithOwner={repoName}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
        />
      ) : (
        <div className="group items-center justify-center text-center">
          <Button
            className={`group relative my-1 w-28 gap-1 py-0.5 text-xs`}
            onClick={() => handleFirstClick()}
            disabled={upsertingTarget}
          >
            {upsertingTarget ? (
              <div className="flex items-center gap-1 text-white">
                Processing
                <LoadingSpinner className="h-3 w-3" />
              </div>
            ) : (
              <>
                Add Contact
                <ChevronDownIcon className="h-4 w-4" />
              </>
            )}
          </Button>
        </div>
      )}
    </>
  );
}
