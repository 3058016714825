import { useRouter } from "next/router";
import { useState, useEffect, type ReactNode } from "react";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import Button from "~/components/base/Button";
import { useLatestEvaluation } from "~/providers/EvaluationProvider";
import { useTarget } from "~/providers/GithubTargetProvider";
import { useLogger } from "~/providers/LoggerProvider";
import { useTargetContact } from "~/providers/TargetContactProvider";
import type {
  RepoEvaluation,
  UserTarget,
  UserEvaluation,
  UserTargetMetadata,
  RepoTargetMetadata,
} from "@openqlabs/drm-db";
import { api } from "~/utils/api";
import { Constants } from "@openqlabs/utils";
import { trpc } from "~/store/trpc";

export default function GenerateSharedActivityReportButton({
  title,
  type,
  children,
}: Readonly<{
  title: string;
  type: "repo" | "user";
  children?: ReactNode | ReactNode[];
}>) {
  const router = useRouter();
  const logger = useLogger();
  const { target, refetchTarget } = useTarget<
    UserTargetMetadata | RepoTargetMetadata
  >();
  const [noCommits, setNoCommits] = useState(false);
  const { targetContact } = useTargetContact();

  const [generatingReport, setGeneratingReport] = useState(false);
  const createReport = api.report.create.useMutation({
    onSuccess: () => {
      refetchTarget().catch((err) => logger.error(err, "reportButton.tsx1"));

      setGeneratingReport(false);
      router
        .push(
          `/target/${type}/${targetContact.teamTargetContactId}/commits/reports`
        )
        .catch((err) => logger.error(err, "reportButton.tsx2"));
    },
    onError: (err) => {
      logger.error(err, "reportButton.tsx3");
      setGeneratingReport(false);
    },
  });

  const lastEvaluation = useLatestEvaluation<UserEvaluation | RepoEvaluation>();

  useEffect(() => {
    if (!lastEvaluation?.result?.commitCount) {
      setNoCommits(true);
    } else {
      setNoCommits(false);
    }
  }, [lastEvaluation]);

  async function generateReport(
    evaluation: UserEvaluation | RepoEvaluation | null
  ) {
    if (!evaluation?.result || !target) {
      return;
    }

    setGeneratingReport(true);
    let login = "";

    let repoUrls: string[] = [];
    if (target.type === "repo") {
      const repoTargetMetadata = target.metadata as RepoTargetMetadata;
      const repoUrl = `${Constants.GITHUB_URL}/${repoTargetMetadata.nameWithOwner}`;
      repoUrls.push(repoUrl);
    } else if (evaluation.type === "user") {
      repoUrls = evaluation.result.children.map(
        (child) => child.url
      ) as string[];
    }
    if (targetContact.type === "user") {
      login = (target as unknown as UserTarget).metadata.login;
    }
    try {
      const { report } = await trpc.aiRouter.commitSummary.mutate({
        repoUrls,
        login,
        type,
        teamAccountId: targetContact.teamAccountId as string,
      });
      if (report.length === 0) {
        setNoCommits(true);
      }

      const reportType =
        type === "repo" ? "repo-commit-summary" : "user-commit-summary";
      createReport.mutate({
        type: reportType,
        params: evaluation.params,
        title,
        body: report,
        targetIds: target?.id ? [target.id] : [],
      });
    } catch (err) {
      logger.error(err, "reportButton.tsx4");
      setNoCommits(true);
      setGeneratingReport(false);
    }
  }

  const isDisabled = generatingReport || noCommits;
  if (!target) {
    return (
      <div>
        Contact does not have an associated github user to generate reports
        from.
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center">
      <Button
        className={`mt-2 ${isDisabled ? "discreet-btn" : ""}`}
        onClick={() => generateReport(lastEvaluation)}
        disabled={isDisabled}
      >
        <LoadingSpinner show={generatingReport} className="mr-2" />
        {children ?? "Generate report"}
      </Button>

      {generatingReport && (
        <p className="mt-2 text-center text-sm text-gray-400">
          This can take a moment. Don&apos;t close this page.
        </p>
      )}
      {noCommits && (
        <p className="mt-2 text-center text-sm text-gray-400">
          No relevant commits found to generate report from on this target.
          Dependabot and merge commits are ignored.
        </p>
      )}
    </div>
  );
}
