import { useRouter } from "next/router";
import { useMemo, useRef, useState } from "react";
import { useCampaigns } from "~/providers/CampaignsProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTracker } from "~/providers/TrackerProvider";
import { api } from "~/utils/api";
import type {
  ComprehensiveTargetData,
  TargetContact,
} from "~/server/api/model/target";

// to handle cases where input is to big for the url string we use posts instead of queries, here are the custom hooks that wrap those as a react query useQuery
import { useQuery } from "@tanstack/react-query";

const useEvaluationsQuery = ({
  campaignId,
  type,
  targetId,
  teamAccountId,
  trackerName,
}: {
  campaignId?: string;
  type: "repo" | "user" | "repo-dependencies" | "user-dependencies";
  targetId: string;
  teamAccountId: string;
  trackerName?: string;
}) => {
  const client = api.useContext().client;

  return useQuery({
    queryKey: ["evaluations", campaignId, type, targetId, teamAccountId],
    queryFn: async () => {
      const optionalTrackerName = trackerName ? { trackerName } : {};
      return await client.evaluation.evaluationsByTypeAndTargetId.mutate({
        campaignId,
        type,
        targetId: targetId,
        teamAccountId,
        ...optionalTrackerName,
      });
    },
  });
};

const useComprehensiveTargetData = (
  type: "repo" | "repo-dependencies" | "user" | "user-dependencies",
  targetContact: TargetContact
): ComprehensiveTargetData | null => {
  const githubTargetId = targetContact?.githubTargetId as string;
  const tracker = useTracker();
  const campaignId = useRouter().query.campaignId as string;
  const { campaigns } = useCampaigns();
  const { activeTeamAccount } = useTeamAccount();

  const [hasLoadedSomeEvals, setHasLoadedSomeEvals] = useState<boolean>(false);

  const { data: evaluations } = useEvaluationsQuery({
    campaignId,
    type,
    targetId: githubTargetId,
    teamAccountId: activeTeamAccount?.id,
  });

  const { data: dependencyEvaluations } = useEvaluationsQuery({
    campaignId,
    type: type.concat("-dependencies") as typeof type,
    targetId: githubTargetId,
    teamAccountId: activeTeamAccount?.id,
    trackerName: tracker?.trackerName,
  });

  const currentTargetMap = useRef<ComprehensiveTargetData | null>(null);

  return useMemo(() => {
    const evaluation =
      evaluations?.find((evaluation) => {
        return evaluation.githubTargetId === targetContact?.githubTargetId;
      }) ?? null;
    const depsEvaluation =
      dependencyEvaluations?.find((evaluation) => {
        return evaluation.githubTargetId === targetContact?.githubTargetId;
      }) ?? null;

    const campaign =
      campaigns.find(
        (campaign) => campaign.id === depsEvaluation?.campaignId
      ) || campaigns[0];

    const result: ComprehensiveTargetData = {
      githubEvaluation: evaluation,
      githubDepsEvaluation: depsEvaluation,
      campaign,
      targetContact: targetContact as NonNullable<TargetContact>,
      tracker: tracker ?? null,
    };
    const firstResult = result;
    const hasFirstResultLoadedEvals = firstResult?.githubEvaluation;
    if (!hasLoadedSomeEvals) {
      if (hasFirstResultLoadedEvals) setHasLoadedSomeEvals(true);

      currentTargetMap.current = result;
      return result;
    }
    if (hasLoadedSomeEvals) {
      if (firstResult?.githubEvaluation) {
        currentTargetMap.current = result;
        return result;
      }
    }
    return currentTargetMap.current;
  }, [
    campaigns,
    evaluations,
    targetContact,
    dependencyEvaluations,
    hasLoadedSomeEvals,
    tracker,
  ]);
};
export default useComprehensiveTargetData;
