import { createContext, useContext, ReactNode } from "react";
import type {
  RepoDependenciesEvaluation,
  UserDependenciesEvaluation,
} from "@openqlabs/drm-db";
import type {
  EvaluationType,
  LiveEvaluation,
} from "~/server/api/model/evaluation";

const latestDepsEvaluationContext = createContext<LiveEvaluation>(null);

export function useLatestDepsEvaluation<
  T extends RepoDependenciesEvaluation | UserDependenciesEvaluation,
>() {
  return useContext(latestDepsEvaluationContext) as T | null;
}

export function DepsEvaluationProvider({
  children,
  depsEvaluation,
}: {
  children: ReactNode;
  type: EvaluationType;
  depsEvaluation: LiveEvaluation | undefined;
}) {
  return (
    <latestDepsEvaluationContext.Provider value={depsEvaluation ?? null}>
      {children}
    </latestDepsEvaluationContext.Provider>
  );
}
