import { maxOne } from "@openqlabs/utils";
import CardScore from "./Score";
import { Skeleton } from "../campaigns/MainPage/SharedTable/utils";
import { TeamRenderTarget } from "~/providers/TargetContactProvider";

export default function CardScores({
  targetContact,
  targetContactIsLoading,
}: {
  targetContact: NonNullable<TeamRenderTarget>;
  targetContactIsLoading: boolean;
}) {
  const { activity = 0, reputation = 0, popularity = 0 } = targetContact;
  const activityPercent = maxOne(activity) * 100;
  let topLang = "";
  if (targetContact.type === "user") {
    targetContact?.languages?.forEach((language) => {
      if (topLang && language > topLang) {
        topLang = language;
      }
    });
  }
  return (
    <div className="flex flex-wrap gap-4">
      <div className="flex flex-col items-end gap-3">
        <span className="mr-2  w-24 self-start font-semibold text-offblack">
          {targetContactIsLoading ? (
            <Skeleton width="px-0" />
          ) : (
            <>OSS Metrics</>
          )}
        </span>
        {targetContact.type === "repo" && (
          <CardScore label="Popularity" score={popularity} />
        )}
        <CardScore label="Reputation" score={reputation} />
        {targetContact.type === "user" && topLang !== "" && (
          <div className="flex items-center self-start ">
            <span className="mr-2 w-24 text-muted">{topLang}</span>
          </div>
        )}
      </div>
      <div className="relative flex flex-col content-start items-start text-left">
        <span className="mr-2  w-24 font-semibold text-offblack">Activity</span>
        <div className="">
          <svg
            className="absolute scale-x-[-1] transform"
            width="120"
            height="120"
            viewBox="0 0 42 42"
          >
            <circle
              className="stroke-[#9ff2f7]"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              strokeLinecap="round"
              strokeWidth="5"
              strokeDasharray={`${activityPercent} ${100 - activityPercent}`}
              strokeDashoffset="25"
            ></circle>
          </svg>
          <svg
            className="absolute scale-x-[-1] transform"
            width="120"
            height="120"
            viewBox="0 0 42 42"
          >
            <circle
              className="stroke-[#9ff2f7]/40"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              strokeLinecap="round"
              strokeWidth="5"
              strokeDasharray={`${90} ${100 - 90}`}
              strokeDashoffset="25"
            ></circle>
          </svg>
          <div className=" relative  flex h-[120px] w-[120px] content-center items-center justify-center rounded-full ">
            <div>{Math.round(maxOne(activity) * 100)}%</div>
          </div>
        </div>
      </div>
    </div>
  );
}
