import { createContext, useContext, type ReactNode } from "react";
import type {
  EvaluationType,
  LiveEvaluation,
} from "~/server/api/model/evaluation";

const latestEvaluationContext = createContext<LiveEvaluation>(null);

export function useLatestEvaluation<TEvaluation>() {
  return useContext(latestEvaluationContext) as TEvaluation;
}

export function EvaluationProvider({
  children,
  evaluation,
}: {
  children: ReactNode;
  type: EvaluationType;
  evaluation: LiveEvaluation | undefined;
}) {
  return (
    <latestEvaluationContext.Provider value={evaluation ?? null}>
      {children}
    </latestEvaluationContext.Provider>
  );
}
