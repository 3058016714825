import { ChevronDownIcon } from "@primer/octicons-react";
import type { Campaign } from "@openqlabs/drm-db";
import { useState, type Dispatch, type SetStateAction } from "react";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import Button from "~/components/base/Button";
import DropDown from "~/components/base/DropDown";
import { useCampaigns } from "~/providers/CampaignsProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/utils/api";
import { useTargetContact } from "~/providers/TargetContactProvider";
import useSettings from "~/hooks/useSettings";

export default function AddUserRepoButton({
  nameWithOwner,
  showDropdown,
  setShowDropdown,
}: Readonly<{
  nameWithOwner: string;
  showDropdown: boolean;
  setShowDropdown: Dispatch<SetStateAction<boolean>>;
}>) {
  const { activeTeamAccount } = useTeamAccount();
  const [teamAccountUser] = useTeamAccountUser();
  const campaignContext = useCampaigns();
  const { targetContact } = useTargetContact();
  const campaigns = campaignContext?.campaigns as Campaign[];

  const [isAddingTarget, setIsAddingTarget] = useState<boolean>(false);
  const { settingItems, refetchSettingsList } = useSettings(
    [],
    targetContact?.githubName as string,
    activeTeamAccount.id
  );

  const { mutate: updateTargetBatch } =
    api.targetBatchCreation.create.useMutation({
      onSuccess: async () => {
        await refetchSettingsList();
      },
    });

  const selectItem = async (_name: string, id?: string | undefined) => {
    if (id && teamAccountUser) {
      setIsAddingTarget(true);
      updateTargetBatch({
        type: "repo",
        campaignId: id,
        teamAccountId: activeTeamAccount.id,
        userId: teamAccountUser.id,
        githubUrls: [
          {
            url: nameWithOwner,
            customFields: [],
          },
        ],
      });
      setIsAddingTarget(false);
      setShowDropdown(false);
    }
  };

  const targetAlreadyAddedEverywhere = Boolean(
    campaigns.length > 0 && settingItems.length === 0
  );

  return (
    <div className="group items-center justify-center text-center">
      <Button
        className={`group relative my-1 w-28 gap-1 py-0.5 text-xs ${
          targetAlreadyAddedEverywhere
            ? "border-gray-400 bg-gray-200 hover:bg-gray-400"
            : ""
        }`}
        onClick={() => setShowDropdown(true)}
        disabled={isAddingTarget || targetAlreadyAddedEverywhere}
      >
        {targetAlreadyAddedEverywhere && <>Target added</>}
        {isAddingTarget && (
          <div className="flex items-center gap-1 text-white">
            Processing
            <LoadingSpinner className="h-3 w-3" />
          </div>
        )}
        {!isAddingTarget && !targetAlreadyAddedEverywhere && (
          <>
            Add Contact
            <ChevronDownIcon className="h-4 w-4" />
            {showDropdown && (
              <DropDown
                closeDropDown={() => setShowDropdown(false)}
                className="btn absolute right-0 top-6 w-48 bg-offwhite px-0 text-sm text-gray-600"
                items={settingItems}
                filterOpen={showDropdown}
                selectItem={(name: string, id?: string) => selectItem(name, id)}
                listClassName="hover:bg-accent hover:text-white px-4"
              />
            )}
          </>
        )}
      </Button>
      {targetAlreadyAddedEverywhere && (
        <div className="relative z-50 opacity-0 group-hover:opacity-100">
          <div className="absolute -ml-40 -mt-12 whitespace-nowrap bg-white px-1 text-sm font-normal text-gray-800">
            Target already added to all campaigns
          </div>
        </div>
      )}
    </div>
  );
}
