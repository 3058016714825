export const chooseStatusColor = (status: string, lightMode?: boolean) => {
  if (lightMode) {
    return getStatusColorLight(status);
  } else {
    return getStatusColor(status);
  }
};

export const chooseStatusText = (status: string, lightMode?: boolean) => {
  if (lightMode) {
    return getStatusTextLight(status);
  } else {
    return getStatusText(status);
  }
};

export const chooseUserTooltip = (status: string, lightMode?: boolean) => {
  if (lightMode) {
    return getUserTooltipLight(status);
  } else {
    return getUserTooltip(status);
  }
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "lead":
    case "lead inactive":
    case "lead churned":
      return "bg-new";
    // "new leads" = leads generated after the campaign end date
    case "new lead":
    case "new lead inactive":
    case "new lead churned":
      return "bg-new";
    case "customer":
    case "customer inactive":
    case "customer churned":
      return "bg-customer";
    case "churned":
      return "bg-churned";
    case "inactive":
      return "bg-blue-200";
    case "cold":
      return "bg-cold";
    default:
      return "bg-gray-100";
  }
};

export const getStatusText = (status: string) => {
  switch (status) {
    case "lead":
      return "lead";
    case "new lead":
      return "new lead";
    case "lead inactive":
      return "lead 😴";
    case "new lead inactive":
      return "new lead 😴";
    case "lead churned":
      return "lead ❌";
    case "new lead churned":
      return "new lead ❌";
    case "customer":
      return "customer";
    case "customer inactive":
      return "customer 😴";
    case "customer churned":
      return "customer ❌";
    case "churned":
      return "churned";
    case "cold":
      return "cold";
    case "inactive":
      return "inactive";
    default:
      return "no status";
  }
};

const getStatusColorLight = (status: string) => {
  switch (status) {
    // in Light mode we only analyze the last 6 weeks for user dependencies
    // hence we can only know if users are active/inactive/churned

    case "lead":
    case "new lead":
    case "customer":
      return "bg-customer";

    case "lead inactive":
    case "new lead inactive":
    case "customer inactive":
    case "inactive":
    case "cold":
      return "bg-blue-200";

    case "lead churned":
    case "new lead churned":
    case "customer churned":
    case "churned":
      return "bg-churned";

    default:
      return "bg-gray-100";
  }
};

const getStatusTextLight = (status: string) => {
  switch (status) {
    // in Light mode we only analyze the last 6 weeks for user dependencies
    // hence we can only know if users are active/inactive/churned

    case "lead":
    case "new lead":
    case "customer":
      return "active";

    case "lead inactive":
    case "new lead inactive":
    case "customer inactive":
    case "inactive":
    case "cold":
      return "inactive";

    case "lead churned":
    case "new lead churned":
    case "customer churned":
    case "churned":
      return "churned";

    default:
      return "no status";
  }
};

const getUserTooltipLight = (status: string) => {
  switch (status) {
    // in Light mode we only analyze the last 6 weeks for user dependencies
    // hence we can only know if users are active/inactive/churned

    case "lead":
    case "customer":
    case "new lead":
      return "User active over the past 6 weeks";

    case "lead inactive":
    case "new lead inactive":
    case "customer inactive":
    case "inactive":
    case "cold":
      return "User inactive over the past 6 weeks";

    case "lead churned":
    case "new lead churned":
    case "customer churned":
    case "churned":
      return "User churned over the past 6 weeks";

    default:
      return "No dependency evaluation result available yet";
  }
};

export const statusRanking = (status?: string) => {
  switch (status) {
    case "customer":
      return 4;
    case "customer inactive":
      return 3;
    case "customer churned":
      return 1;
    case "lead":
      return 2;
    case "lead inactive":
      return 2;
    case "lead churned":
      return 2;
    case "churned":
      return 1;
    case "cold":
      return 1;
    case "inactive":
      return 1;
    default:
      return 0;
  }
};

const getUserTooltip = (status: string) => {
  switch (status) {
    case "lead":
      return "Lead generated during campaign";
    case "new lead":
      return "Lead generated post campaign";
    case "lead inactive":
      return "New user but inactive for 6 weeks";
    case "new lead inactive":
      return "Lead generated post campaign but inactive for 6 weeks";
    case "lead churned":
      return "New user but dependency was removed";
    case "new lead churned":
      return "Lead generated post campaign but dependency was removed";
    case "customer":
      return "";
    case "customer inactive":
      return "Returning user but inactive for 6 weeks";
    case "customer churned":
      return "Returning user but dependency was removed";
    case "churned":
      return "User churned before campaign start";
    case "inactive":
      return "User inactive since campaign start";
    case "cold":
      return "";
    default:
      return "No evaluation result available yet";
  }
};

export const getRepoTooltip = (
  status: string,
  earliestDate: string,
  dateRemoved: string | null,
  manualStatus?: boolean
) => {
  if (manualStatus) {
    return "Repo manually marked active";
  }
  const earliestDateFormatted = `${earliestDate.split("T")[0] ?? ""}`;
  const dateRemovedFormatted = `${dateRemoved?.split("T")[0] ?? ""} `;
  switch (status) {
    case "lead":
      return `Active since ${earliestDateFormatted}`;
    case "new lead":
      return `Post-campaign lead, active since ${earliestDateFormatted}`;
    case "lead inactive":
      return `Added on ${earliestDateFormatted} but repository inactive for the last 6 weeks`;
    case "new lead inactive":
      return `Post-campaign lead, added on ${earliestDateFormatted} but repository inactive for the last 6 weeks`;
    case "lead churned":
      return `Added on ${earliestDateFormatted} but removed on ${
        dateRemovedFormatted ?? ""
      }`;
    case "new lead churned":
      return `Post-campaign lead, added on ${earliestDateFormatted} but removed on ${
        dateRemovedFormatted ?? ""
      }`;
    case "customer":
      return `Active since ${earliestDateFormatted}`;
    case "customer inactive":
      return `Added on ${earliestDateFormatted} but repository inactive for the last 6 weeks`;
    case "customer churned":
      return `Added on ${earliestDateFormatted} but removed on ${
        dateRemovedFormatted ?? ""
      }`;
    case "churned":
      return `Dependency removed on ${dateRemovedFormatted ?? ""}`;
    case "inactive":
      return `Added on ${earliestDateFormatted} but inactive since campaign start`;
    case "cold":
      return "Dependency not found in repository";
    default:
      return "";
  }
};
