export default function DependenciesLegend() {
  return (
    <div className="flex items-center space-x-4 py-1 text-xs">
      <div className="flex items-center gap-2">
        <div className={`h-3 w-3 rounded-full bg-new`} />
        <div>First used during campaign</div>
      </div>
      <div className="flex items-center gap-2">
        <div className={`h-3 w-3 rounded-full bg-customer`} />
        <div>Used before campaign start</div>
      </div>
      <div className="flex items-center gap-2">
        <div className={`h-3 w-3 rounded-full bg-churned`} />
        <div>Removed before campaign</div>
      </div>
    </div>
  );
}
