import {
  getRepoTooltip,
  getStatusColor,
  getStatusText,
} from "~/lib/dependencyHelpers";
import { type UserDependenciesEvaluation } from "@openqlabs/drm-db";
import AddUserRepoToCampaign from "./AddUserRepoToCampaign";
import { useCampaign } from "~/providers/CampaignProvider";
import { useTargetContact } from "~/providers/TargetContactProvider";
import Link from "next/link";
import { decodeStatusAsObject, Enums } from "@openqlabs/utils";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export default function UserDependenciesFoundPresentation({
  latestDepsEvaluation,
  contributorRepos,
  dependenciesSearched,
}: Readonly<{
  latestDepsEvaluation: UserDependenciesEvaluation;
  contributorRepos: string[];
  dependenciesSearched: string[];
}>) {
  const campaign = useCampaign();
  if (!campaign) return null;
  const lightMode = campaign?.mode == Enums.CampaignMode.LIGHT ?? false;
  const coldOrInactive = lightMode ? "inactive" : "cold";
  const depsStatusPerRepo = latestDepsEvaluation?.result?.userRepoSummary;

  const { targetContact } = useTargetContact();

  // only keeping the below code because of the multiple dep logic for now

  return (
    <div>
      {dependenciesSearched.map((dependency, index) => {
        const depStatusObj = decodeStatusAsObject(
          targetContact.dependencyStatusNumber,
          undefined
        );
        return (
          <div key={dependency && index} className="flex flex-col pt-2">
            <div className="flex">
              <span className="font-bold">{dependency}</span>:
              <div
                className={`group mx-2 whitespace-nowrap rounded-md px-4 ${depStatusObj.color}`}
              >
                {depStatusObj.status}
                <div className="relative z-50 opacity-0 group-hover:opacity-100">
                  <div className="absolute -mt-10 whitespace-nowrap bg-white px-1">
                    {depStatusObj.tooltip}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-4">
              <div className="flex flex-col py-2">
                {contributorRepos?.map((repoName, index) => {
                  const repoDepOverview =
                    depsStatusPerRepo[repoName]?.[dependency];
                  const repoStatus =
                    repoDepOverview?.repoStatus ?? coldOrInactive;
                  const repoText = repoDepOverview?.manualStatus
                    ? "active"
                    : getStatusText(repoStatus);
                  const noDepFound =
                    !repoDepOverview?.earliestFound &&
                    !repoDepOverview?.manualStatus;

                  return (
                    <div
                      className="flex items-center justify-between"
                      key={repoName}
                    >
                      <div
                        key={repoName && dependency && index}
                        className="group flex items-center space-x-1 py-2 pr-4"
                      >
                        <div
                          className={`h-3 w-3 rounded-full ${getStatusColor(
                            repoStatus
                          )}`}
                        />
                        <Link
                          href={`https://github.com/${repoName}`}
                          target="_blank"
                        >
                          <div className="px-1 flex gap-1">
                            {repoName}
                            <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block" />
                          </div>
                        </Link>
                        <div
                          className={`whitespace-nowrap rounded-md px-4 ${getStatusColor(
                            repoStatus
                          )}`}
                        >
                          {repoText}
                        </div>
                        {!noDepFound && (
                          <div className="relative z-50 opacity-0 group-hover:opacity-100">
                            <div className="absolute -ml-60 -mt-6 whitespace-nowrap bg-white px-1">
                              {getRepoTooltip(
                                repoDepOverview.repoStatus,
                                repoDepOverview?.earliestFound ?? "",
                                repoDepOverview.dateRemoved,
                                repoDepOverview.manualStatus
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <AddUserRepoToCampaign
                        repoName={repoName}
                        key={repoName}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
